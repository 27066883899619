<template>
    <div>
        <div class="container-fluid ">
            <div class="container my-5 py-5">
                <div class="d-flex flex-column align-items-center">
                    <img class="col-8 col-lg-4 mb-5" :src="vector.url" alt="" />
                    <h1 class="col-12 col-lg-8 fw-bold text-center text-darkblue my-4">
                        OUTSOURCED ACCOUNTING AND TAX COMPLIANCE SERVICES
                    </h1>
                    <div class="col-12 col-lg-6 text-center mb-5">
                        Monthly packages including accounting, tax & payroll compliance. You can now focus on your business, we take care of the rest!
                    </div>
                    <div class="col-12 col-lg-10">
                        <div class="row">
                            <div v-for="(pack, packIndex) in packages" :key="packIndex" class="col-12 col-lg-6 d-flex">
                                <div :class="packIndex ? 'blue' : 'grey'" class="card price-card my-4 rounded-3">
                                    <div class="card-body p-5">
                                        <h5 :class="packIndex ? 'text-blue' : 'text-darkgrey'" class="text-capitalize">
                                            {{ pack.title }}
                                        </h5>
                                        <h6 class="text-grey fw-light mb-4">
                                            for up to {{ pack.transactions }} transactions
                                        </h6>
                                        <div class="d-flex align-items-end mb-4">
                                            <h3 :class="packIndex ? 'text-blue' : 'text-darkgrey'" class="mb-0">THB {{ pack.price }}</h3>
                                            <h6 class="text-grey fw-light ms-2 mb-0">/ month</h6>
                                        </div>
                                        <ul>
                                            <li v-for="(service, serviceIndex) in pack.services" :key="serviceIndex" class="py-1">
                                                {{ service }}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.price-card.grey {
    border-color: var(--darkGrey);
    border-top: 12px solid var(--darkGrey);
}
.price-card.blue {
    border-color: var(--royal);
    border-top: 12px solid var(--royal);
}
li {
    list-style: "—   ";
}
</style>
<script>
export default {
    data() {
        return {
            vector: {
                url: "https://firebasestorage.googleapis.com/v0/b/jnpaccounting-5fe5f.appspot.com/o/imgs%2Fvector%2Fworking.svg?alt=media&token=378e7173-eaa0-46fe-93c2-00d324e9bc3d"
            },
            packages: [
                {
                    title: "starter",
                    transactions: 10,
                    price: "10,000",
                    services: [
                        "Bookkeeping",
                        "Payroll for up to 2 employees (additional employees at THB300/pay-slip)",
                        "Monthly Tax filing (VAT, Withholding Tax)",
                        "Monthly PND1 and Social Security fund"
                    ],
                },
                {
                    title: "standard",
                    transactions: 25,
                    price: "15,000",
                    services: [
                        "Bookkeeping",
                        "Payroll for up to 2 employees (additional employees at THB300/pay-slip)",
                        "Monthly Tax filing (VAT, Withholding Tax)",
                        "Monthly PND1 and Social Security fund filing",
                        "Cloud accounting software services"
                    ],
                },
            ]
        }
    }
}
</script>